/* Styles for landing page image and diagonal coloured div */

/* Base styles  */
.LandingPageTop {
  background-image: url("../assets/hero.jpg");
  background-position: 0 0;
  background-size: 960px 640px;
  background-repeat: no-repeat;
  overflow: hidden;
}

/* Technique for creating divs with diagonal lines is from */
/* https://9elements.com/blog/pure-css-diagonal-layouts/ */
.DiagonalBoxPrimary {
  @apply bg-primary;
  position: relative;
  z-index: 0;
  top: 40vh;
  height: 100vh;
  width: 100%;
  max-width: 1920px;
  transform: skewY(-20deg);
}

/* How to use the text shadow property to create an outline on text is from */
/* https://stackoverflow.com/questions/57464935/font-outline-using-only-css */
.Content {
  transform: skewY(20deg);
  position: relative;
  top: -8vh;
  mix-blend-mode: difference;
  outline: #000000;
  text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black, 0 0 2px black;
  color: #ffffff;
}

/* Media queries - these are bespoke based on the size of the hero image, rather than standard Tailwind breakpoints */
/* Styles for very small devices less than 390px width */
@media (max-width: 390px) {
  .LandingPageTop {
    background-size: 800px 535px;
  }
}

/* Styles for devices wider than 835px */
@media (min-width: 835px) {
  .LandingPageTop {
    background-size: 1440px 957px;
  }

  .DiagonalBoxPrimary {
    @apply bg-primary;
    transform: skewY(-30deg);
  }

  .Content {
    transform: skewY(30deg);
  }

  .ContentInner {
    position: relative;
    top: 12vh;
    left: 7vw;
  }
}

/* Styles for devices wider than 1435px */
@media (min-width: 1435px) {
  .LandingPageTop {
    background-size: 1920px 1276px;
    transform: skewY(-deg);
  }

  .DiagonalBoxPrimary {
    transform: skewY(-40deg);
    height: 150vh;
  }

  .Content {
    transform: skewY(40deg);
  }
  
  .ContentInner {
    position: relative;
    top: 12vh;
    left: 20vw;
  }
}

/* Styles for Nest Hub */
@media (width: 1024px) and (height:600px) {  
  .ContentInner {
    left: 14vw;
  }
}