.ContactDetailsExpand {
  animation: 0.5s expand;
}

@keyframes expand {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}