/* Container for calendar dot indicators */
.CalDotDiv {
  position: relative;
}

/* Default styles for calendar dots */
/* How to use mix-blend-mode to display a colour opposite to the background is from */
/* https://stackoverflow.com/questions/17741629/how-can-i-invert-color-using-css*/
.CalDot {
  position: absolute;
  background-color: white;
  mix-blend-mode: difference;
  margin-left: -10px;
  margin-top: 2px;
}

/* Styles for calendar dots at medium breakpoint */
@media (min-width: 768px) {
  .CalDot {
    margin-left: -4px;
    margin-top: 4px;
  }
}

/* Styles for calendar dots at large breakpoint */
@media (min-width: 1024px) {
  .CalDot {
    margin-left: -6px;
    margin-top: 8px;
  }
}

